import PropTypes from 'prop-types'
import React from 'react'
import VideoItem from './VideoItem'

const RealStoriesVideos = ({ data }) => {
  return (
    <>
      {data.map((item, index) => (
        <VideoItem
          item={item}
          reverse={index % 2 !== 0}
          key={`${index}-${item.title}`}
        />
      ))}
    </>
  )
}

RealStoriesVideos.propTypes = {
  data: PropTypes.array.isRequired,
}

export default React.memo(RealStoriesVideos)
