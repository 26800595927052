export const sliderSettings = {
  infinite: true,
  arrows: true,
  dots: false,
  slidesToScroll: 1,
  speed: 200,
  responsive: [
    {
      breakpoint: 360,
      settings: {
        speed: 100,
        slidesToShow: 1,
        centerMode: true,
      },
    },
    {
      breakpoint: 390,
      settings: {
        speed: 100,
        slidesToShow: 1.1,
        centerMode: true,
      },
    },
    {
      breakpoint: 565,
      settings: {
        speed: 100,
        slidesToShow: 1.2,
        centerMode: true,
      },
    },
    {
      breakpoint: 565,
      settings: {
        speed: 100,
        slidesToShow: 2,
        centerMode: true,
      },
    },
    {
      breakpoint: 696,
      settings: {
        speed: 100,
        slidesToShow: 2,
        centerMode: true,
      },
    },
    {
      breakpoint: 767,
      settings: {
        speed: 100,
        slidesToShow: 2.3,
        centerMode: true,
      },
    },
    {
      breakpoint: 900,
      settings: {
        speed: 100,
        slidesToShow: 2.5,
        centerMode: true,
      },
    },
    {
      breakpoint: 1023,
      settings: {
        slidesToShow: 3.2,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1147,
      settings: {
        slidesToShow: 3.8,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 4.2,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 1700,
      settings: {
        slidesToShow: 4.8,
        slidesToScroll: 6,
      },
    },
    {
      breakpoint: 1800,
      settings: {
        slidesToShow: 5.5,
        slidesToScroll: 6,
      },
    },
    {
      breakpoint: 2000,
      settings: {
        slidesToShow: 5.5,
        slidesToScroll: 6,
      },
    },
    {
      breakpoint: 10000,
      settings: {
        slidesToShow: 5.5,
        slidesToScroll: 6,
      },
    },
  ],
}
