import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import Img from '../../../../../../../src/components/shared/Img'
import { Modal } from '../../../../../shared/Modal'
import VideoPlayer from 'src/components/shared/VideoPlayer'
import SvgIcon from '../../../../../shared/SvgIcon'

const ReviewItem = ({ item }) => {
  const [playVideo, setPlayVideo] = useState(false)
  const handleVideoPLaying = useCallback(() => {
    setPlayVideo((current) => !current)
  }, [setPlayVideo])

  return (
    <>
      <div className="relative px-4 lg:px-0">
        <Img
          src={item.img.src}
          alt={item.img.alt}
          layout="fill"
          className="h-[415px] w-[300px]"
          objectFit="cover"
          imgClassName="md:object-top"
        />
        <div className="z-50 h-full w-full bg-light-black">
          <div className="absolute bottom-0 left-0 z-50 px-6 pb-8 text-white">
            <b className="text-23 leading-8">
              <span>{item.name}</span>
              <span className="pb-3">*</span>
            </b>
            <div className="pb-6 pt-2 text-15 leading-6">
              <p>Graduated {item.graduateDate}</p>
              <p>Enrolled debt: {item.debt}</p>
              <p>Number of accounts settled: {item.settleNumber}</p>
            </div>
            <button
              className="flex flex-row rounded-lg bg-blue-575 px-2.5 py-3 text-white"
              onClick={handleVideoPLaying}
            >
              <SvgIcon
                name="play"
                className="my-auto mr-2 h-5 w-5 fill-white"
              />
              <b className="text-lg">Watch {item.pronoun} story</b>
            </button>
          </div>
        </div>
      </div>
      {playVideo ? (
        <Modal
          open={playVideo}
          onClose={handleVideoPLaying}
          className="bg-black-base pb-4 pr-14 pt-9"
        >
          <VideoPlayer
            url={item.videoLink}
            light={false}
            className="h-screen w-screen lg:h-[567px] lg:w-[1000px]"
          />
        </Modal>
      ) : null}
    </>
  )
}

export default ReviewItem

ReviewItem.propTypes = {
  item: PropTypes.object.isRequired,
}
