import React from 'react'
import PropTypes from 'prop-types'
import TrustPilotReviews from '../../../shared/BrandPages/TrustPilotReviews'
import { testimonialsData } from './consts'
import HeroSection from '../../../shared/Rewrite/BrandPages/HeroSection'
import BottomSection from '../../../shared/BrandPages/BottomSection'
import RecommendedReads from '../../../shared/BrandPages/RecommendedReads'
import RealStoriesVideos from './RealStoriesVideos'
import BrandSection from '../../../shared/BrandPages/BrandSection'
import Section from '../../../shared/BrandPages/Section'
import FreedomDebtVideos from './FreedomDebtVideos'
import { FAQPageJsonLd } from 'next-seo'
import Content from '../../../shared/BrandPages/Content'
import CustomJsonLd from '../../../shared/CustomJsonLd'
import styles from './index.module.scss'
import Accordion from 'src/components/shared/FAQ/Accordion'
import PrimaryButton from 'src/components/shared/PrimaryButton'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { jsonLdData } from '../../../../common/constants/jsonLd'
import WithSeoOverrides from 'src/components/shared/WithSeoOverride'

const TestimonialsPage = ({ data }) => {
  const { reviews, banner = {} } = data
  const {
    seoData,
    heroData,
    realStoriesVideosData,
    recommendedReadsData,
    bottomSectionData,
    clientTestimonialsData,
    faqs,
    freedomDebtStoryData,
  } = testimonialsData

  // const { isDesktop } = useDeviceDetect()

  const JsonLdData = faqs.map((item) => ({
    questionName: item.title,
    acceptedAnswerText: documentToHtmlString(item.content).replace(/"/g, "'"),
  }))

  const sliderSettings = {
    infinite: true,
    slidesToScroll: 1,
    speed: 200,
    arrows: false,
    responsive: [
      {
        breakpoint: 360,
        settings: {
          speed: 100,
          slidesToShow: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 390,
        settings: {
          speed: 100,
          slidesToShow: 1.1,
          centerMode: true,
        },
      },
      {
        breakpoint: 565,
        settings: {
          speed: 100,
          slidesToShow: 1.2,
          centerMode: true,
        },
      },
      {
        breakpoint: 565,
        settings: {
          speed: 100,
          slidesToShow: 2,
          centerMode: true,
        },
      },
      {
        breakpoint: 696,
        settings: {
          speed: 100,
          slidesToShow: 2,
          centerMode: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          speed: 100,
          slidesToShow: 2,
          centerMode: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          speed: 100,
          slidesToShow: 3,
          centerMode: true,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1147,
        settings: {
          arrows: true,
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          arrows: true,
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1700,
        settings: {
          arrows: true,
          slidesToShow: 5,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 1800,
        settings: {
          arrows: true,
          slidesToShow: 5,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 2000,
        settings: {
          arrows: true,
          slidesToShow: 5,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 10000,
        settings: {
          arrows: true,
          slidesToShow: 5,
          slidesToScroll: 6,
        },
      },
    ],
  }

  return (
    <Content>
      <FAQPageJsonLd mainEntity={JsonLdData} />
      <CustomJsonLd
        id={'webPage'}
        data={jsonLdData(
          seoData?.title,
          seoData?.description,
          '/testimonials/'
        )}
      />
      <WithSeoOverrides {...seoData} />
      <Section classNames="pb-0 pt-0 lg:pl-4 xl:pl-0">
        <HeroSection
          {...heroData}
          contentClassName={styles.contentClassName}
          heroContainerClassName={styles.heroClassName}
          overlayShadowClassName={styles.overlayShadow}
        />
      </Section>
      <Section classNames={`pt-12.2 bg-blue-base-white pb-0 lg:py-12.2`}>
        <BrandSection
          {...clientTestimonialsData}
          childClassName="order-last mt-0 lg:mt-3.5"
          wrapperClassName="mt-8 lg:mt-0"
        >
          <RealStoriesVideos data={realStoriesVideosData} />
        </BrandSection>
      </Section>
      <Section>
        <BrandSection {...freedomDebtStoryData} childClassName="order-last">
          <FreedomDebtVideos videos={freedomDebtStoryData.videoList} />
        </BrandSection>
      </Section>
      <Section>
        <TrustPilotReviews
          reviews={reviews}
          slickSliderSettings={sliderSettings}
          trustBoxClassName="bg-blue-base-white lg:px-20"
          itemClassname="bg-white"
        />
      </Section>
      <Section classNames={'pb-8 pt-0 lg:pt-12 lg:pb-20'}>
        <p className="p-4 text-center text-2.5xl font-bold leading-10 text-black-base lg:px-0 lg:py-5 lg:text-37 lg:leading-52">
          FAQs
        </p>
        <Accordion
          questionClassName="font-medium lg:pt-5.4"
          answerClassName="pl-4 pr-12 text-15 lg:pl-0"
          items={faqs}
        />
        <div className="order-4 flex justify-center pt-5 ">
          <PrimaryButton
            sectionTitle="FAQs"
            title="Read more"
            href="/faq/"
            className="bg-blue-base px-8 py-2.5 text-white"
          />
        </div>
      </Section>
      <Section classNames="bg-blue-base-white">
        <RecommendedReads data={recommendedReadsData} />
      </Section>
      <Section classNames="pb-0 pt-0">
        <BottomSection {...bottomSectionData} />
      </Section>
    </Content>
  )
}

TestimonialsPage.propTypes = {
  data: PropTypes.object.isRequired,
}
export default TestimonialsPage
