import React, { useMemo } from 'react'
import PrimaryButton from 'src/components/shared/PrimaryButton'
import PropTypes from 'prop-types'
import { useLeadId } from 'src/common/lead-id'
import { useRouter } from 'next/router'
import { buildQueryString } from 'src/common/utils'

export const CrawlableCta = ({ buttonLink, title, buttonText, isDark }) => {
  const cookieLeadId = useLeadId()
  const { query: baseQuery } = useRouter()

  let query = useMemo(
    () => buildQueryString(baseQuery, cookieLeadId),
    [baseQuery, cookieLeadId]
  )

  const url = useMemo(() => {
    return cookieLeadId ? `${buttonLink}?${query}` : buttonLink
  }, [buttonLink, cookieLeadId, query])

  return (
    <PrimaryButton
      isLeadCta
      id={`bottomSection`}
      sectionTitle={title}
      title={buttonText}
      className={`${
        isDark ? 'bg-sky-650 text-gray-980' : 'bg-blue-base text-white'
      } mx-auto mb-4 inline-block px-7 py-2.5`}
      href={url}
    />
  )
}

CrawlableCta.propTypes = {
  buttonLink: PropTypes.string,
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  isDark: PropTypes.bool,
}
