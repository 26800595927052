import PropTypes from 'prop-types'
import React, { useState } from 'react'
import AccordionItem from './AccordionItem'
import { FAQPageJsonLd } from 'next-seo'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { addHyperlinkTitle } from '../../../../common/helpers'
import classNames from 'classnames'

const Accordion = ({
  items,
  title,
  titleCenter,
  questionClassName,
  answerClassName,
  titleClassName,
  showFirstDivider,
}) => {
  const [marked, setMarked] = useState(undefined)
  const handleChangeCollapse = (id) => {
    if (id === marked) {
      setMarked(undefined)
    } else {
      setMarked(id)
    }
  }

  const JsonLdData = items.map((item) => ({
    questionName: item.title,
    acceptedAnswerText: documentToHtmlString(item.content).replace(/"/g, "'"),
  }))

  if (!(items.length > 2)) return null

  return (
    <>
      <FAQPageJsonLd mainEntity={JsonLdData} />
      <div className="m-auto max-w-full px-5 lg:max-w-906 lg:px-2">
        {title ? (
          <div
            data-testid="faq title"
            className={classNames(
              'my-5.4 leading-10 text-black-base lg:my-8',
              titleCenter && 'text-center',
              titleClassName
            )}
          >
            {title}
          </div>
        ) : null}
        <div data-testid="itemList" className="mb-8 border-b border-gray-540">
          {items.map((f, i) => {
            const { title: question, content } = f

            addHyperlinkTitle(content?.content, question)

            return (
              <AccordionItem
                title={question}
                content={content}
                key={i}
                id={i}
                opened={marked === i}
                changeCollapse={handleChangeCollapse}
                questionClassName={questionClassName}
                answerClassName={answerClassName}
                showFirstDivider={showFirstDivider}
              />
            )
          })}
        </div>
      </div>
    </>
  )
}

Accordion.defaultProps = {
  items: [],
  titleCenter: false,
  titleClassName:
    'text-1.5xl lg:text-3.5xl lg:leading-primary-xs lg:text-gray-910',
}
Accordion.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string,
  titleCenter: PropTypes.bool,
  questionClassName: PropTypes.string,
  answerClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  showFirstDivider: PropTypes.bool,
}

export default Accordion
