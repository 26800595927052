import React from 'react'
import PropTypes from 'prop-types'
import Img from '../../../../../src/components/shared/Img'
import PhoneNumber from '../../PhoneNumber'
import { APPLY_FDR_URL } from '../../../../common/constants'
import UnCrawlableLink from '../../UnCrawlableLink'

import { CrawlableCta } from './CrawlableCta'

const BottomSection = ({
  title,
  list,
  image: { src, alt },
  isDark,
  buttonText,
  buttonLink,
  showPhoneNumber,
  className,
  isLeadId,
  crawlable,
}) => {
  return (
    <div
      className={`${isDark ? 'bg-blue-base text-white' : 'bg-blue-base-light'}`}
      data-testid="bottomSection"
      id="bottomSection"
    >
      <div className="w-content gap-x-primary padding-x-primary relative grid grid-cols-4 lg:grid-cols-12">
        <div className="col-span-4 py-6 text-center lg:col-span-5 lg:col-start-2 lg:pb-12 lg:pt-14 lg:text-left">
          <h3
            className={`max-w-514 mx-auto mb-4 text-32 font-bold leading-11 lg:text-37 lg:leading-primary-xs ${className}`}
            data-testid="title"
          >
            {title}
          </h3>
          {list ? (
            <ul className="mb-7 lg:mb-4">
              {list.map((item, i) => (
                <li key={i} className="text-lg lg:text-xl">
                  {item}
                </li>
              ))}
            </ul>
          ) : null}
          {crawlable ? (
            <CrawlableCta
              title={title}
              isLeadId={isLeadId}
              buttonLink={buttonLink}
              buttonText={buttonText}
              isDark={isDark}
            />
          ) : (
            <UnCrawlableLink
              id={`bottomSection`}
              href={buttonLink}
              className={`${
                isDark ? 'bg-sky-650 text-blue-base' : 'bg-blue-base text-white'
              } mx-auto mb-4 inline-block rounded-lg px-12 py-2.5 text-lg font-bold`}
              label={buttonText}
            />
          )}
          {showPhoneNumber ? (
            <p className="text-15">
              <PhoneNumber
                text="800-910-0065"
                speakText="Or speak to a debt consultant "
                href="tel:800-910-0065"
                isTrack={true}
                className={`${
                  isDark ? 'text-white' : 'text-black-base'
                } font-bold no-underline`}
              />
            </p>
          ) : null}
        </div>
        {src ? (
          <div className="col-span-full lg:col-span-6 lg:col-start-7 xl:col-span-4">
            <div className="flex h-full flex-col justify-end">
              <Img
                src={src}
                alt={alt}
                data-testid="img"
                className="h-64 md:h-80"
                objectFit="contain"
                imgClassName="object-bottom lg:object-left-bottom"
                styles={{ position: 'relative' }}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

BottomSection.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  image: PropTypes.object.isRequired,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  isDark: PropTypes.bool,
  showPhoneNumber: PropTypes.bool,
  isLeadId: PropTypes.bool,
  crawlable: PropTypes.bool,
  isLeadCta: PropTypes.bool,
}
BottomSection.defaultProps = {
  buttonText: 'Get your free evaluation',
  buttonLink: APPLY_FDR_URL(),
  isLeadCta: true,
  isDark: false,
  showPhoneNumber: true,
  isLeadId: false,
  crawlable: true,
}

export default React.memo(BottomSection)
