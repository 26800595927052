import React from 'react'
import LazyLoad from 'react-lazyload'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
import useDeviceDetect from '../../../../../common/hooks/useDeviceDetect'
import ReviewItem from './ReviewItem'
import { setDotsClassName } from '../../../../../common/helpers'

const SlickSlider = dynamic(() =>
  import('../../../../shared/BrandPages/SlickSlider')
)

const FreedomDebtVideosComponent = ({ videos }) =>
  videos.map((item, index) => <ReviewItem key={index} item={item} />)

const FreedomDebtVideos = ({ videos }) => {
  const { isMobile } = useDeviceDetect(true)

  const settings = {
    dots: true,
    infinite: false,
    arrows: false,
    speed: 500,
    afterChange: (current) => {
      setDotsClassName(current, document, 'slider-debt-relief-steps')
    },
    responsive: [
      {
        breakpoint: 361,
        settings: {
          slidesToShow: 1.14,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1.19,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1.24,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1.32,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.24,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.3,
          slidesToScroll: 2,
        },
      },
    ],
  }

  return (
    <div className="pt-12 lg:pt-16" id="debt-videos">
      {isMobile ? (
        <LazyLoad placeholder={<FreedomDebtVideosComponent videos={videos} />}>
          <SlickSlider
            sliderSettings={settings}
            name="debt-relief-steps"
            dotsClassName="pb-8"
          >
            {videos.map((item, index) => (
              <ReviewItem key={index} item={item} />
            ))}
          </SlickSlider>
        </LazyLoad>
      ) : (
        <div className="flex flex-row items-center justify-center gap-x-8">
          <FreedomDebtVideosComponent videos={videos} />
        </div>
      )}
    </div>
  )
}

export default React.memo(FreedomDebtVideos)

FreedomDebtVideos.propTypes = {
  videos: PropTypes.array.isRequired,
}
