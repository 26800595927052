import React from 'react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import { CSSTransition } from 'react-transition-group'

const FullPageModal = ({ open, onClose, children }) =>
  createPortal(
    <CSSTransition
      in={open}
      timeout={200}
      classNames={{
        enter: 'opacity-0',
        enterActive: 'opacity-100 duration-200',
        exitActive: 'opacity-0 duration-200',
      }}
      unmountOnExit
      mountOnEnter
    >
      <div className="fixed inset-0 z-20 flex h-screen w-screen flex-col bg-white p-2">
        <div className="flex w-full flex-row justify-end">
          <button
            type="button"
            className="grow-0 px-2 text-2xl"
            onClick={onClose}
          >
            x
          </button>
        </div>
        {children}
      </div>
    </CSSTransition>,
    document.body
  )

FullPageModal.defaultProps = {
  open: false,
}
FullPageModal.propTypes = {
  children: PropTypes.element.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
}

export default React.memo(FullPageModal)
