import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Img from '../../../../../../../src/components/shared/Img'
import { generateElementId } from '../../../../../../common/helpers'

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'short', day: '2-digit' }
  const date = new Date(dateString)

  return date.toLocaleDateString('en-US', options)
}

const TrustBoxItem = ({
  id,
  title,
  text,
  consumer,
  date,
  stars,
  handleClick,
  itemClassname,
}) => {
  return (
    <>
      <div
        className={`${classNames(
          `ml-3 mr-3 h-46 w-auto rounded px-3 py-4 drop-shadow-sm hover:cursor-pointer lg:m-2 ${itemClassname}`
        )}`}
        id={generateElementId(text)}
        onClick={() => handleClick(id)}
      >
        <Img
          className="h-5 w-24"
          alt="stars"
          src={`${
            stars === 5
              ? '/next-assets/trustpilot/trustpilot-five-stars.svg'
              : '/next-assets/trustpilot/trustpilot-four-stars.svg'
          }`}
        />
        <p className="py-3 text-sm font-bold" data-testid="reviews-title">
          {title.length <= 25 ? title : title.substr(0, 25).concat('...')}
        </p>
        <p className="h-16 pb-1 text-sm" data-testid="reviews-text">
          {text.length <= 80 ? text : text.substr(0, 80).concat('...')}
        </p>
        <div className="py-2 text-sm">
          <p>
            <span className="font-bold text-gray-300">
              {consumer.displayName}
            </span>
            , <span className="text-gray-250">{formatDate(date)}</span>
          </p>
        </div>
      </div>
    </>
  )
}

TrustBoxItem.defaultProps = {
  itemClassname: 'bg-white-110',
}

TrustBoxItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  consumer: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
  stars: PropTypes.number.isRequired,
  itemClassname: PropTypes.string,
  handleClick: PropTypes.func,
}

export default TrustBoxItem
