import React from 'react'
import TrustBox from './TrustBox'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'

const TrustPilotReviews = ({
  reviews,
  itemClassname,
  trustBoxClassName,
  slickSliderSettings,
}) => {
  if (!reviews?.length) {
    return null
  }

  return (
    <LazyLoad offset={200}>
      <TrustBox
        reviews={reviews}
        itemClassname={itemClassname}
        trustBoxClassName={trustBoxClassName}
        slickSliderSettings={slickSliderSettings}
      />
    </LazyLoad>
  )
}

TrustPilotReviews.propTypes = {
  reviews: PropTypes.array.isRequired,
  itemClassname: PropTypes.string,
  trustBoxClassName: PropTypes.string,
  slickSliderSettings: PropTypes.object,
}
export default TrustPilotReviews
