import PropTypes from 'prop-types'
import React from 'react'
import Hyperlink from '../../HyperLink'
import SvgIcon from '../../SvgIcon'
import classNames from 'classnames'

const PrimaryLink = ({
  name,
  href,
  onClick,
  className,
  hasArrowIcon,
  title,
  id,
}) => {
  const handleClick = () => {
    if (typeof onClick === 'function') {
      onClick()
    }

    return null
  }

  return (
    <div
      className="my-auto flex flex-row items-center justify-center pt-5"
      data-testid="primary link"
      id={`primary_${id}`}
    >
      {href ? (
        <Hyperlink
          href={href}
          childrenClassNames={classNames(
            'cursor-pointer pr-2.5 text-base font-bold leading-5 text-blue-base',
            className
          )}
          title={title}
        >
          {name}
        </Hyperlink>
      ) : (
        <button
          className={classNames(
            'cursor-pointer pr-2.5 text-base font-bold leading-5 text-blue-base',
            className
          )}
          onClick={handleClick}
        >
          {name}
        </button>
      )}
      {hasArrowIcon ? <SvgIcon name="arrowRight" /> : null}
    </div>
  )
}

PrimaryLink.defaultProps = {
  href: '',
  onClick: () => {},
  className: '',
  hasArrowIcon: false,
  title: '',
  id: 'primary-link',
  name: 'Primary link',
}
PrimaryLink.propTypes = {
  name: PropTypes.string.isRequired,
  href: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  hasArrowIcon: PropTypes.bool,
  id: PropTypes.string,
}
export default React.memo(PrimaryLink)
