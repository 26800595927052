import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import PrimaryButton from '../../PrimaryButton'
import PrimaryLink from '../PrimaryLink'
import RichText from '../../RichText'
import styles from './index.module.scss'
import { useLeadId } from '../../../../common/lead-id'
import useDeviceDetect from '../../../../common/hooks/useDeviceDetect'
import { useRouter } from 'next/router'
import { buildQueryString } from '../../../../common/utils'

const BrandSection = ({
  tagline,
  title,
  description,
  hasLink,
  hasButton,
  button,
  link,
  childClassName,
  onClick,
  children,
  descriptionClassname,
  isLeadId,
  primaryBtnClassName,
  wrapperClassName,
  showInMobile,
}) => {
  const cookieLeadId = useLeadId()
  const { isMobile } = useDeviceDetect()
  const { query: baseQuery } = useRouter()

  let query = useMemo(
    () => buildQueryString(baseQuery, cookieLeadId),
    [baseQuery, cookieLeadId]
  )

  const showBtn = useMemo(
    () => hasButton && (!isMobile || showInMobile),
    [isMobile, hasButton, showInMobile]
  )

  const id = useMemo(
    () =>
      title
        .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
        .replaceAll(' ', ''),
    [title]
  )

  let url = button.href
  if (isLeadId && cookieLeadId) {
    url = `${url}?${query}`
  }

  return (
    <div
      className={`flex flex-col ${wrapperClassName}`}
      id={`brand-section-${id}`}
    >
      {tagline ? (
        <h3 className="text-center text-15 font-medium leading-5 text-blue-base">
          {tagline}
        </h3>
      ) : null}
      <h2 className="whitespace-normal p-4 text-center text-2.5xl font-bold leading-10 text-black-base lg:whitespace-pre-line lg:px-0 lg:py-5 lg:text-37 lg:leading-52">
        {title}
      </h2>
      <RichText
        contentData={description}
        className={`${styles.description} ${descriptionClassname}`}
      />
      {hasLink ? (
        <div className="order-3">
          <PrimaryLink
            id={id}
            title={title}
            name={link.name}
            href={link.href}
            onClick={onClick}
            hasArrowIcon={link.hasArrowIcon}
          />
        </div>
      ) : null}
      {showBtn ? (
        <div
          className="order-4 flex justify-center pt-5"
          data-testid="primary btn"
        >
          <PrimaryButton
            id={id}
            sectionTitle={title}
            title={button.name}
            isLeadCta={button?.isLeadCta}
            href={url}
            className={`${primaryBtnClassName} px-4 py-3 text-white`}
          />
        </div>
      ) : null}
      {children ? <div className={childClassName}>{children}</div> : null}
    </div>
  )
}

BrandSection.defaultProps = {
  childClassName: 'order-last',
  descriptionClassname: '',
  onClick: () => {},
  children: null,
  hasLink: false,
  hasButton: true,
  button: {},
  link: {},
  isLeadId: false,
  hasExperiment: false,
  primaryBtnClassName: 'bg-red-950',
  wrapperClassName: '',
  showInMobile: false,
}

BrandSection.propTypes = {
  tagline: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.object,
  hasLink: PropTypes.bool,
  hasButton: PropTypes.bool,
  button: PropTypes.object,
  link: PropTypes.object,
  childClassName: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  descriptionClassname: PropTypes.string,
  isLeadId: PropTypes.bool,
  hasExperiment: PropTypes.bool,
  primaryBtnClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  showInMobile: PropTypes.bool,
}

export default BrandSection
