import React from 'react'
import LazyLoad from 'react-lazyload'
import RecommendedReadsItem from './RecommendedReadsItem'
import CustomSlider from './CustomSlider'

export const RecommendedReadsComponent = ({ data }) =>
  data.map((items, index) => <RecommendedReadsItem {...items} key={index} />)

const RecommendedReads = ({ data }) => {
  return (
    <div
      className="w-content gap-x-primary grid grid-cols-4 lg:grid-cols-12"
      id="recommended-reads"
    >
      <div className="col-span-full lg:col-span-11 lg:col-start-2">
        <h3 className="px-10 pb-8 text-center text-2.5xl font-bold lg:px-0 lg:pb-16 lg:text-37">
          Learn more about Freedom Debt Relief
        </h3>
        <div className="lg:mx-auto">
          <LazyLoad placeholder={<RecommendedReadsComponent data={data} />}>
            <CustomSlider data={data} />
          </LazyLoad>
        </div>
      </div>
    </div>
  )
}

export default React.memo(RecommendedReads)
